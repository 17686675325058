export const MAIN_API = process.env.REACT_APP_MAIN_API_URL
export const MESSAGE_SERVICE_API = process.env.REACT_APP_MESSAGE_SERVICE_API_URL

export const CLIENT_NAME = 'MySMS'
export const CLIENT_TOKEN = process.env.REACT_APP_CLIENT_TOKEN

export const INACTIVE_EXPIRE_PERIOD = 15 * 60 * 1000
// export const EXPIRE_PERIOD = 1 * 60 * 1000

export const MODAL_MSGES = {
  LOGIN_INVALID: 'No user found for this username/password!!',
  ERROR_MSG: 'Somthing Went Wrong!! Please Try Again.',
  SEARCH_NO_DATA_DOUND:
    "Sorry, we couldn't find any data matching your request!! Please check your search criteria or try again later.",
  NO_DATA_FOUND: 'No Data Found!! This might be because there is no data available at the moment',
  CAMPAIGN: {
    ADD_SUCCESS_MSG:
      "You've successfully created a new campaign to the system. The campaign details have been saved and are now available in the carousal.",
    UPDATE_SUCCESS_MSG:
      "You've successfully updated the campaign. The campaign details have been saved and are now available in the carousal.",
  },
  TEMPLATE: {
    ADD_SUCCESS_MSG:
      "You've successfully created a new template to the system. The template details have been saved and are now available in the carousal.",
    UPDATE_SUCCESS_MSG:
      "You've successfully updated the template. The template details have been saved and are now available in the carousal.",
  },
}

export const PER_MSG_COST = 0.36

export const COLORS = {
  MAIN: '#406E84',
  SECONDARY: '#EB9D5D',
  MID_LIGHT: '#D2CFDF',
  LIGHT: '#FFF4EB',
  DANGER_BTN: '#C40202',
}
export const COUNTRY_DIAL_CODES = [
  {
    code: '94',
    name: 'Sri Lanka',
    flag: 'https://flagcdn.com/w320/lk.png',
  },
  {
    code: '7 840',
    name: 'Abkhazia',
    flag: 'https://flagcdn.com/w320/ge.png',
  },
  {
    code: '93',
    name: 'Afghanistan',
    flag: 'https://flagcdn.com/w320/af.png',
  },
  {
    code: '355',
    name: 'Albania',
    flag: 'https://flagcdn.com/w320/al.png',
  },
  {
    code: '213',
    name: 'Algeria',
    flag: 'https://flagcdn.com/w320/dz.png',
  },
  {
    code: '1 684',
    name: 'American Samoa',
    flag: 'https://flagcdn.com/w320/as.png',
  },
  {
    code: '376',
    name: 'Andorra',
    flag: 'https://flagcdn.com/w320/ad.png',
  },
  {
    code: '244',
    name: 'Angola',
    flag: 'https://flagcdn.com/w320/ao.png',
  },
  {
    code: '1 264',
    name: 'Anguilla',
    flag: 'https://flagcdn.com/w320/ai.png',
  },
  {
    code: '1 268',
    name: 'Antigua and Barbuda',
    flag: 'https://flagcdn.com/w320/ag.png',
  },
  {
    code: '54',
    name: 'Argentina',
    flag: 'https://flagcdn.com/w320/ar.png',
  },
  {
    code: '374',
    name: 'Armenia',
    flag: 'https://flagcdn.com/w320/am.png',
  },
  {
    code: '297',
    name: 'Aruba',
    flag: 'https://flagcdn.com/w320/aw.png',
  },
  {
    code: '247',
    name: 'Ascension',
    flag: 'https://flagcdn.com/w320/sh.png',
  },
  {
    code: '61',
    name: 'Australia',
    flag: 'https://flagcdn.com/w320/au.png',
  },
  {
    code: '672',
    name: 'Australian External Territories',
    flag: 'https://flagcdn.com/w320/nf.png',
  },
  {
    code: '43',
    name: 'Austria',
    flag: 'https://flagcdn.com/w320/at.png',
  },
  {
    code: '994',
    name: 'Azerbaijan',
    flag: 'https://flagcdn.com/w320/az.png',
  },
  {
    code: '1 242',
    name: 'Bahamas',
    flag: 'https://flagcdn.com/w320/bs.png',
  },
  {
    code: '973',
    name: 'Bahrain',
    flag: 'https://flagcdn.com/w320/bh.png',
  },
  {
    code: '880',
    name: 'Bangladesh',
    flag: 'https://flagcdn.com/w320/bd.png',
  },
  {
    code: '1 246',
    name: 'Barbados',
    flag: 'https://flagcdn.com/w320/bb.png',
  },
  {
    code: '1 268',
    name: 'Barbuda',
    flag: 'https://flagcdn.com/w320/ag.png',
  },
  {
    code: '375',
    name: 'Belarus',
    flag: 'https://flagcdn.com/w320/by.png',
  },
  {
    code: '32',
    name: 'Belgium',
    flag: 'https://flagcdn.com/w320/be.png',
  },
  {
    code: '501',
    name: 'Belize',
    flag: 'https://flagcdn.com/w320/bz.png',
  },
  {
    code: '229',
    name: 'Benin',
    flag: 'https://flagcdn.com/w320/bj.png',
  },
  {
    code: '1 441',
    name: 'Bermuda',
    flag: 'https://flagcdn.com/w320/bm.png',
  },
  {
    code: '975',
    name: 'Bhutan',
    flag: 'https://flagcdn.com/w320/bt.png',
  },
  {
    code: '591',
    name: 'Bolivia',
    flag: 'https://flagcdn.com/w320/bo.png',
  },
  {
    code: '387',
    name: 'Bosnia and Herzegovina',
    flag: 'https://flagcdn.com/w320/ba.png',
  },
  {
    code: '267',
    name: 'Botswana',
    flag: 'https://flagcdn.com/w320/bw.png',
  },
  {
    code: '55',
    name: 'Brazil',
    flag: 'https://flagcdn.com/w320/br.png',
  },
  {
    code: '246',
    name: 'British Indian Ocean Territory',
    flag: 'https://flagcdn.com/w320/io.png',
  },
  {
    code: '1 284',
    name: 'British Virgin Islands',
    flag: 'https://flagcdn.com/w320/vg.png',
  },
  {
    code: '673',
    name: 'Brunei',
    flag: 'https://flagcdn.com/w320/bn.png',
  },
  {
    code: '359',
    name: 'Bulgaria',
    flag: 'https://flagcdn.com/w320/bg.png',
  },
  {
    code: '226',
    name: 'Burkina Faso',
    flag: 'https://flagcdn.com/w320/bf.png',
  },
  {
    code: '257',
    name: 'Burundi',
    flag: 'https://flagcdn.com/w320/bi.png',
  },
  {
    code: '855',
    name: 'Cambodia',
    flag: 'https://flagcdn.com/w320/kh.png',
  },
  {
    code: '237',
    name: 'Cameroon',
    flag: 'https://flagcdn.com/w320/cm.png',
  },
  {
    code: '1',
    name: 'Canada',
    flag: 'https://flagcdn.com/w320/ca.png',
  },
]
